<template>
    <div>
        <div class="shop-sidebar-cart">
            <div v-if="getCartCount > 0">
                <ul class="sidebar-cart-list">
                    <li v-for="(item, ndx) in getCartItems" :key="ndx">
                        <div class="cart-widget">
                            <div class="dz-media me-3">
                                <img :src="item.imageID" alt="">
                            </div>
                            <div class="cart-content">
                                <div class="row">
                                    <div class="col-12">
                                        <h6 class="title"><a @click="goProductPage(item.slug)">{{ item.name }}<br>({{item.size}}) ({{ item.colorName }})</a></h6>
                                    </div>
                                    <div class="col-12">
                                        <div class="btn-quantity light quantity-sm me-3">
                                            <button @click="updateQuantity('D', item)" class="btn btn-default btnQty" type="button">
                                                <i class="fa fa-minus"></i>
                                            </button>
                                            <input  readonly type="text" v-model="item.quantity" min="1" name="demo_vertical2" max="10" @input="updateTotalPrice(item)">
                                            <button @click="updateQuantity('I', item)"  class="btn btn-default btnQty" type="button">
                                                <i class="fa fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <h6 class="dz-price mb-0">{{item.currency}} {{ (item.quantity * item.productPrice).toFixed(2)}}</h6>
                                    </div>
                                </div>
                            </div>
                            <a @click="deleteItem(ndx)" class="dz-close cursor-pointer">
                                <i class="fa fa-trash"></i>
                            </a>
                        </div>
                    </li>
                </ul>
                <div class="cart-total">
                    <h5 class="mb-0">Subtotal:</h5>
                    <h5 class="mb-0">{{ appSettings.currency }} {{ subtotal.toFixed(2) }}</h5>
                </div>
                <div class="mt-auto">
                    <div class="shipping-time">													
                        <div class="dz-icon">
                            <i class="fa fa-truck"></i>
                        </div>
                        <div class="shipping-content">
                            <h6 class="title pe-4">Congratulations , you've got free shipping!</h6>
                            
                        </div>
                    </div>
                    <button @click="goPage('checkout')" class="btn btn-outline-secondary btn-block m-b20">Checkout</button>	
                    <button @click="goPage('cart')" class="btn btn-secondary btn-block">View Cart</button>	
                </div>	
            </div>
            <div v-else>
                <p>Sorry. You don't have any products in your cart</p>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import settings from '../../settings.json'
export default {
    name: 'miniCart',
    props: [ 'tab'],
    data() {
        return {
            appSettings: settings
        }
    },
    computed: {
		...mapState(["user", "cart","logInfo","wishList","showPop"]),
        ...mapGetters(["subtotal"]),
        getCartCount () {
            return this.getCartItems.length
        },
        getCartItems () {
            return this.cart;					  
        },
        getSubTotal () {
            return 'KD 200.00'
        }
    },
    methods: {
        ...mapActions(["delete_item", "update_quantity"]),
        deleteItem(index) {
            this.delete_item(index);
        },
		goPage (pg) {
			this.$emit('cartNavigate', pg)
		},
       
		updateQuantity (typ, item) {
            var qty = item.quantity
            if (typ === 'D') {
                qty = qty - 1
                if (qty < 1) qty = 1
            }
            if (typ === 'I') {
                qty = qty + 1
                if (qty > 10) qty = 10
            }
            const payload = { id: item.id, size: item.size, color: item.color, quantity: qty }
            this.update_quantity(payload);
        },
        goProductPage (slug) {
            this.$router.push('/shop/' + slug)
        }
    }

}
</script>