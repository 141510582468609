<template>
    <button aria-label="button" v-bind="$attrs">
        {{ btnvalue }}
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "ActionButton",
    props: {
        btnvalue: String,
    },
};
</script>

<style scoped>
button {
    border: none;
    background-color: var(--logo1);
    padding: 10px 20px;
    border: 2px solid var(--logo2);
    font-size: 1.7rem;
    color: #000;
}

button:hover {
    background-color: var(--logo2);
    border-color: var(--logo1);
}
</style>
