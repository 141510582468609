<template>
    <div>
        <div class="shop-sidebar-cart">
            <ul class="sidebar-cart-list" v-if="getWishListCount > 0">
                <li v-for="(items, ndx) in getWishListItems" :key="ndx">
                    <div class="cart-widget">
                        <div class="dz-media me-3">
                            <img @click="goProductPage(items)" class="cursor-pointer" :src="items.img" alt="">
                        </div>
                        <div class="cart-content">
                            <h6 class="title cursor-pointer"><a @click="goProductPage(items)">{{ items.name }}</a></h6>
                            <div class="d-flex align-items-center">
                                <h6 class="dz-price  mb-0">{{items.currency}} {{ items.price }}</h6>
                            </div>
                        </div>
                        <a @click="removeWishlist(items)" class="dz-close cursor-pointer">
                            <i class="fa fa-trash"></i>
                        </a>
                    </div>
                </li>
            </ul>
            <p v-if="getWishListCount === 0">
                Sorry. You don't have any products added to your wishlist
            </p>	
        </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
    name: 'miniWishList',
    data() {
        return {
            tabPane: 'shopping-cart-pane',
            tabStyle: ''
        }
    },
    computed: {
		...mapState(["user", "cart","logInfo","wishList"]),
        getWishListCount () {
            return this.getWishListItems.length
        },
        getWishListItems () {
			return this.wishList;
        }
    },
    methods: {
		goProductPage (item) {
			this.$router.push('/shop/' + item.slug)
		},
		removeWishlist (item) {
			const payload = { id: item.id, name: item.name }
			this.$store.dispatch('removeWishList', payload)
		}
    }

}
</script>