<template>
    
    <main-header />
    <!-- <section class="hero__section">
        <div class="container">
            <div class="hero__text">
                <h4>Trade-in-offer</h4>
                <h1>Super value deals</h1>
                <h2>On all products</h2>
                <p>Save more with coupons & get up to 70% off!</p>
                <router-link to="/shop">
                    <action-button btnvalue="Start Shopping" />
                </router-link>
            </div>
        </div>
    </section> -->
    <section class="feature__section">
        <div class="container">
            <featured-products :featuredProducts="featuredProducts" />
        </div>
    </section>
    <!-- <banner-section /> -->
    <new-arrivals :newArrivals="newArrivals" />
    <section class="feature__section">
        <div class="container">
            <top-selling :topSelling="topSelling"/>
        </div>
    </section>
    <!-- <service-section /> -->
    <!-- <news-letter v-if="appSettings.newsLetter === 1"/> -->
    <main-footer />
</template>

<script>
import ServiceSection from "@/components/home_components/ServiceSection.vue";
import BannerSection from "@/components/home_components/BannerSection.vue";
import NewArrivals from "@/components/home_components/products/NewArrivals.vue";
import FeaturedProducts from "@/components/home_components/products/FeaturedProducts.vue";
import TopSelling from "@/components/home_components/products/TopSelling.vue";
import ActionButton from "@/components/ActionButton.vue";
import MainHeader from "@/components/MainHeader.vue";
import NewsLetter from "@/components/NewsLetter.vue";
import MainFooter from "@/components/MainFooter.vue";
import settings from '../../settings.json'
// import { mapState } from "vuex";
import axios from "axios";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
    name: "HomeView",
    data() {
        return {
            products: [],
            appSettings: settings
        };
    },
    methods: {
        ...mapActions(["set_products"]),
        getStyle () {
            return 'background-image: url("' + this.appSettings.bannerImage + '")'
        }
    },
    components: {
        ServiceSection,
        BannerSection,
        NewArrivals,
        FeaturedProducts,
        ActionButton,
        MainHeader,
        NewsLetter,
        MainFooter,
        TopSelling
    },
    computed: {
        ...mapState(["user", "cart","logInfo"]),
        featuredProducts() {
            return this.products.filter(i => i.featured === 1);
        },
        newArrivals() {
            return this.products.filter(i => i.newarrival === 1);
        },
        topSelling() {
            return this.products.filter(i => i.topselling === 1);
        },
    },
    async created() {
        const url = this.appSettings.apiURL + 'api/http/selflogin'
        await axios.post(url)
          .then((response) => {
            this.loading = false
            this.auto_login(response.data);
            this.getProducts(response.data.token);
          })
          .catch((error) => {
          });
    },
    methods: {
        ...mapActions(["remove_user","auto_login", "set_products"]),
        async getProducts (token) {
            const url = this.appSettings.apiURL + 'api/smr/products';
            const headers = {crossDomain: true, Accept: 'application/json',  'Access-Control-Allow-Credentials': 'true'}
            let res1 = await axios.get(url);
            this.products = res1.data.product_details.map((product) => {
                product.images[0] = product.images[0];
                return product;
            });
            this.set_products(this.products);
        }
    }
};
</script>

<style scoped>
/* Hero Section */
.hero__section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 65px);
    background-image: url("https://sahooracollection.com/media/home_banner.jpg");
    background-position: 60% 30%;
    background-size: cover;
}
@media (max-width: 992px){
.hero__section {
   align-items: bottom;
   background-image: url("https://sahooracollection.com/media/home_banner_mobile.jpg");
    background-position: top center;
    background-size: cover;
}
}
.hero__text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.hero__text h4 {
    padding-bottom: 1rem;
}

.hero__text h1 {
    color: var(--dim-blue);
}

.hero__text p {
    margin-bottom: 1rem;
}

@media (min-width: 2000px) {
    .hero__section {
        background-position: 70% 12%;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: #e3e6f3;
        height: 50vh;
    }
}

@media (min-width: 3000px) {
    .hero__section {
        background-position: 60% 30%;
        /* background-size: cover; */
    }
}
</style>
