<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="currentColor"
         class="bi bi-heart"
        viewBox="0 0 16 16"
    >
        <path
            fill-rule="evenodd"
            d="M8 14s-5-3.5-5-6.5c0-2.29 1.91-4.38 5-7.5 3.09 3.12 5 5.21 5 7.5C13 10.5 8 14 8 14z"
        />
    </svg>
</template>