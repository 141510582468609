<template>
    <div>
        <div class="offcanvas dz-offcanvas offcanvas offcanvas-end " :style="tabStyle">
		    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" @click="closeCartDialog()">&times;</button>
		    <div class="offcanvas-body">
			    <div class="product-description">
				<div class="dz-tabs">
					<ul class="nav nav-tabs center" id="myTab" role="tablist">
						<li class="nav-item" role="presentation">
							<button @click="setActiveTab('shopping-cart-pane')" :class="getTabClass('shopping-cart-pane','TAB')" id="shopping-cart" data-bs-toggle="tab" data-bs-target="#shopping-cart-pane" type="button" role="tab" aria-controls="shopping-cart-pane" aria-selected="true">
                                Shopping Cart<span class="badge badge-light">{{getCartCount}}</span>
                            </button>
						</li>
						<li class="nav-item" role="presentation">
							<button @click="setActiveTab('wishlist-pane')" :class="getTabClass('wishlist-pane','TAB')" id="wishlist" data-bs-toggle="tab" data-bs-target="#wishlist-pane" type="button" role="tab" aria-controls="wishlist-pane" aria-selected="false">
                                Wishlist <span class="badge badge-light">{{ getWishListCount }}</span>
							</button>
						</li>
						</ul>
						<div class="tab-content pt-4" id="dz-shopcart-sidebar">
							<div :class="getTabClass('shopping-cart-pane','SHEET')" id="shopping-cart-pane" role="tabpanel" aria-labelledby="shopping-cart" tabindex="0">
								<miniCart @cartNavigate="cartNavigate"></miniCart>	
							</div>
							<div :class="getTabClass('wishlist-pane','SHEET')" id="wishlist-pane" role="tabpanel" aria-labelledby="wishlist" tabindex="0">
								<miniWishList></miniWishList>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import miniWishList from '@/components/common/miniWishList.vue';
import miniCart from '@/components/common/miniCart.vue';
export default {
    name: 'cartDialog',
    // props: ['showPop', 'tab'],
	components: { miniWishList, miniCart },
    data() {
        return {
            tabPane: 'shopping-cart-pane',
            tabStyle: ''
        }
    },
    watch: {
        showPop: function(newVal, oldVal) {
            if (newVal === true) {
                this.tabStyle = 'visibility: visible !important;transform:none;'
            } else {
                this.tabStyle = ''
            }
        },
        tab: function(newVal, oldVal) {
            this.tabPane = newVal
        }
    },
	computed: {
		...mapState(["user", "cart","logInfo","wishList","showPop","tab"]),
        getCartCount () {
            return this.cart.length
        },
        getCartItems () {
            return this.cart;					  
        },
        getWishListCount () {
            return this.getWishListItems.length
        },
        getWishListItems () {
			return this.wishList;
        }
    },
    methods: {
		goProductPage (item) {
			this.$router.push('/shop/' + item.slug)
		},
        closeCartDialog() {
            this.tabStyle = ''
            const payload = { show: false, tab: '' }
            this.$store.dispatch('setCartPop', payload)
        },
		goPage (pg) {
			this.tabStyle = ''
            const payload = { show: false, tab: '' }
            this.$store.dispatch('setCartPop', payload)
			this.$emit('closeCartDialog')
			this.$router.push('/' + pg)
		},
        setActiveTab (tab) {
            this.tabPane = tab
        },
        getTabClass (tab, typ) {
            if (typ === 'TAB') return this.tabPane === tab ? 'nav-link active' : 'nav-link'
            if (typ == 'SHEET') return this.tabPane === tab ? 'tab-pane fade show active' : 'tab-pane fade'
        },
		updateQuantity (typ, item) {
            // var qty = item.quantity
            // if (typ === 'D') {
            //     qty = qty - 1
            //     if (qty < 1) qty = 1
            // }
            // if (typ === 'I') {
            //     qty = qty + 1
            //     if (qty > 10) qty = 10
            // }
            // item.quantity = qty
        },
		cartNavigate (pg) {
			this.tabStyle = ''
			this.$router.push('/' + pg)
		}
    }

}
</script>