<template>
  <div class="col d-flex flex-column justify-content-between">
    <div class="p-1">
      <div class="product__card d-flex flex-column justify-content-between"  style="cursor:pointer;">
        <div class="d-flex flex-column justify-content-between" style="max-height:350px;height:350px;">
          <img @click="$router.push('/cart/' + productId)" :src="mainImage" :alt="brand" class="object-fit-scale img-fluid img-responsive" loading="lazy" style="height: 100%;"/>
        </div>
        <p class="product__name" style="font-weight:bold" @click="$router.push('/cart/' + productId)">{{ item.name }}</p>
        <p class="product__name text-truncate" @click="$router.push('/cart/' + productId)" v-html="item.description"></p>
        <!-- <div class="price"> -->
            <div class="row price">
                <div class="col-7"><span>{{ formattedPrice }}</span></div>
                <div class="col-5">
                    <button class="cart" @click="$router.push('/cart/' + productId)" aria-label="View Item">
                        <i class="fa fa-cart-plus"></i>
                    </button>
                    <!-- <button class="cart" @click="addWishList(item)" aria-label="View Item" v-if="wishListExists(item) === 0">
                        <i class="fa-regular fa-heart fa-lg"></i>
                    </button>
                    <button class="cart" @click="removeWishList(item)" aria-label="View Item" v-if="wishListExists(item) !== 0">
                        <span style="color:red"><i class="fa-solid fa-heart fa-lg"></i></span>
                    </button> -->
          <!-- <button class="cart" @click="$router.push('/cart/' + productId)" aria-label="View Item">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-cart3" viewBox="0 0 16 16">
              <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg>
          </button> -->
          <!-- <div> -->
            <!-- <span style="color:black"><i class="fa-regular fa-heart fa-lg"></i></span>
            <span style="color:red"><i class="fa-solid fa-heart fa-lg"></i></span> -->
        <!-- </div> -->
                </div>
            <!-- </div> -->
          
          

         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "ProductCard",
  props: [
    "productId",
    "image_url",
    "brand",
    "name",
    "price",
    "currency",
    "ratings",
    "in_stock",
    "item"
  ],
  computed: {
    ...mapState(["user", "cart","logInfo","wishList"]),
    formattedPrice() {
      return this.currency + " " + this.price.toFixed(2);
    },
    mainImage() {
      let lst = this.item.images;
      var url = "";
      var ctr = 1;
      var defURL = "";
      this.item.images.forEach(function(p) {
        if (ctr === 1) defURL = p.image;
        if (p.color === "") url = p.image;
        ctr++;
      });
      if (url === "") url = defURL;
      return url;
    }
  },
  methods: {
    addWishList(item) {
        const message = {
          icon: "icon-shield-check",
          message: " has been added to your wishlist!"
        };
        this.$store.dispatch('addWishList', item)
    },
    removeWishList(item) {
      this.$store.dispatch('removeWishList', item)
    },
    wishListExists (item) {
        var id = item.id
        let lst = this.wishList.filter(i => i.id === id)
        return lst.length
    }
  }
};
</script>

<style scoped>
.product__card {
  border: 1px solid var(--grey);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.05s ease-in;
  padding: 15px;
  text-align: left;
  border-radius: 15px;
  color: #222;
}

.product__image {
  height: auto;
  max-width: 100%;
  border-radius: 5px;
}

.product__name {
  padding-block: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.brand {
  font-size: 1.5rem;
  display: inline-block;
  font-weight: 700;
}

.product__qty {
  font-size: 1.5rem;
  display: inline-block;
  padding-top: 10px;
  background-color: var(--dark-green);
  color: white;
  border-radius: 5px;
  padding: 5px;
}

.rating {
  padding-bottom: 5px;
  color: gold;
}

.rating i {
  font-size: 1.5rem;
}

.price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price span {
  font-size: 1.6rem;
  font-weight: 700;
}

.cart {
  cursor: pointer;
  height: 35px;
  width: 35px;
  color: var(--dark-green);
  border-radius: 50%;
  font-size: 1.9rem;
  display: inline-grid;
  place-items: center;
  text-align: center;
  background-color: var(--light-green);
}
</style>
