<template>
    <main-header v-if="page !== 'landing'"/>
    <div class="page-content bg-light">
        <main>
            <component :is="$route.meta.layout || 'div'">
                <router-view />
                <!-- <router-view-transition transition="fade" mode="out-in" /> -->
            </component>
        </main>
    </div>
    <main-footer  v-if="page !== 'landing'" />
</template>

<script>
// @ is an alias to /src
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import { useToast } from "vue-toastification";
import { RouterViewTransition } from 'vue-router-view-transition'
import { mapActions, mapState } from "vuex";
export default {
    name: "App",
    components: { MainHeader, MainFooter, useToast, RouterViewTransition },
    data () {
        return {
            // showPop: false,
            toast: null,
            toastOptions: {
                position: "bottom-center",
                timeout: 2000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            },
            page: ''
        }
    },
    watch: {
        toastMessage: function (newVal, oldVal) {
            this.toastMessage1 = newVal
            if (newVal.typ === 'info' || newVal.typ === '') {
                this.toast.info(newVal.message, this.toastOptions);
            }
            if (newVal.typ === 'warning') {
                this.toast.warning(newVal.message, this.toastOptions);
            }
            if (newVal.typ === 'success') {
                this.toast.success(newVal.message, this.toastOptions);
            }
            if (newVal.typ === 'error') {
                this.toast.error(newVal.message, this.toastOptions);
            }
        },
        '$route': {
            handler: function(search) {
                this.page = search.name
                const payload = { show: false, tab: ''}
                this.$store.dispatch('setCartPop', payload)
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        ...mapState(["user", "cart", "logInfo","toastMessage"])
    },
    mounted () {
        this.page = this.$route.name
        this.toast = useToast();
    },
    methods: {
        cartPopup() {
            const payload = { show: true, tab: tab }
            this.$store.dispatch('setCartPop', payload)
            // this.showPop = true
        }
    }
};
</script>
