import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/store"
import LandingPage from '../views/User/LandingView.vue';
import HomeView from "../views/User/HomeView.vue";
const routes = [
  { path: "/", name: "landing", component: LandingPage },
  { path: "/home", name: "Home", component: HomeView },
  { path: '/login', name: 'login', component: () => import("../views/UserRegistration/Login.vue") },
  // { path: '/signup', name: 'signup', component: () => import("../views/UserRegistration/SignupBox.vue") },
  { path: '/register', name: 'register', component: () => import("../views/UserRegistration/Register.vue") },
  { path: '/forgot-password', name: 'forgot-password', component: () => import("../views/UserRegistration/ForgotPassword.vue") },
  { path: "/about", name: "about", component: () => import(/* webpackChunkName: "about" */ "../views/User/AboutView.vue") },
  { path: "/shop", name: "shop", component: () => import("../views/User/ShopView.vue") },
  { path: "/newarrivals", name: "newarrivals", component: () => import("../views/User/NewArrivalsView.vue") },

  { path: "/contact", name: "contact", component: () => import("../views/User/ContactView.vue") },
  { path: "/cart", name: "cart", component: () => import("../views/User/CartView.vue") },
  { path: "/checkout", name: "checkout", component: () => import("../views/User/CheckoutView.vue"),
    // beforeEnter: (to, from, next) => {
    //   if (store.state.user) {
    //     next("/checkout");
    //   } else {
    //     next("/login");
    //   }
    // }
  },
  { path: "/my-wishlist", name: "myWishList", component: () => import("../views/User/Wishlist.vue"),
   
  },
  { path: "/track-order", name: "trackOrder", component: () => import("../views/User/CheckoutView.vue"),
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next("/login");
      }
    }
  },
  { path: "/my-order", name: "myOrders", component: () => import("../views/User/CartView.vue"),
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next("/my-order");
      }
    }
  },
  { path: '/cart/:id', name: 'cartItem', component: () => import("../views/User/CartItemView.vue") },
  { path: '/shop/:id', name: 'viewProduct', component: () => import("../views/User/CartItemView.vue") },
  { path: '/delivery-information', name: 'deliveryInformation', component: () => import("../views/User/DeliveryInformation.vue") },
  { path: '/privacy-policy', name: 'privacyPolicy', component: () => import("../views/User/PrivacyPolicy.vue") },
  { path: '/terms-and-conditions', name: 'termsAndConditions', component: () => import("../views/User/TermsConditions.vue") },
  { path: '/help', name: 'help', component: () => import("../views/User/Help.vue") },
  { path: '/faqs', name: 'FAQ', component: () => import('../views/User/faq.vue')}
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ left:0, top: savedPosition.top })
        }, 500)
      })
    } else {
      return { top: 0 }
    }
  }
});

export default router;
