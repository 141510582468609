<template>
    <header class="site-header mo-left header">
        <div class="sticky-header main-bar-wraper navbar-expand-lg">
			<div class="main-bar clearfix">
				<div class="container-fluid clearfix d-lg-flex d-block">
					<div class="logo-header logo-dark me-md-5">
						<router-link to="/shop"><img :src="appSettings.logo"></router-link>
					</div>				
					<button class="navbar-toggler collapsed navicon justify-content-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
					<span></span>
					<span></span>
					<span></span>
				</button>
				<div class="header-nav navbar-collapse collapse justify-content-start" id="navbarNavDropdown">
					<div class="logo-header logo-dark">
						<a href="index.html"><img :src="appSettings.logo"></a>
					</div>
					<ul class="nav navbar-nav dark-nav">
						<li class="has-mega-menu sub-menu-down">
                            <router-link @click="hideNav" to="/shop"><span>Shop</span></router-link>
                        </li>
                        <li class="has-mega-menu sub-menu-down">
                            <router-link @click="hideNav" to="/newarrivals"><span>New Arrivals</span></router-link>
                        </li>
                        <li class="has-mega-menu sub-menu-down"><router-link to="/about"><span>About Us</span></router-link></li>
                        <li class="has-mega-menu sub-menu-down"><router-link to="/contact"><span>Contact Us</span></router-link></li>
					</ul>
					
					<div class="dz-social-icon">
						<ul>
							<li><a class="fab fa-facebook-f" target="_blank" href="https://www.facebook.com/dexignzone"></a></li>
							<li><a class="fab fa-twitter" target="_blank" href="https://twitter.com/dexignzones"></a></li>
							<li><a class="fab fa-linkedin-in" target="_blank" href="https://www.linkedin.com/showcase/3686700/admin/"></a></li>
							<li><a class="fab fa-instagram" target="_blank" href="https://www.instagram.com/dexignzone/"></a></li>
						</ul>
					</div>
				</div>
				<!-- EXTRA NAV -->
				<div class="extra-nav">
					<div class="extra-cell">
						<ul class="header-right">
							<li class="nav-item login-link" v-if="userLogged === false">
								<router-link class="nav-link" to="/login">
									Login / Register
                                </router-link>
							</li>
                            <li class="nav-item login-link cursor-pointer" v-if="userLogged === true">
								<a class="nav-link" @click="logout">
									Logout
                                </a>
							</li>
                            <li class="nav-item wishlist-link" v-if="userLogged === true">
								<a class="nav-link" style="cursor:pointer" @click="showCartPopup('shopping-cart-pane')" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                    <i class="fa fa-user"></i>
								</a>
							</li>
							<li class="nav-item wishlist-link">
								<a class="nav-link" style="cursor:pointer" @click="showCartPopup('wishlist-pane')" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
									<i class="fa fa-heart"></i>
                                    <span class="badge badge-circle" v-if="getWishlistCount !== 0">{{ getWishlistCount }}</span>
								</a>
							</li>
							<li class="nav-item cart-link">
								<a @click="showCartPopup('shopping-cart-pane')" style="cursor:pointer"   class="nav-link cart-btn"  data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
									<i class="fa fa-shopping-cart"></i>
									<span class="badge badge-circle" v-if="getCartCount !== 0">{{ getCartCount }}</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
				</div>
			</div>
		</div>
        <cartDialog :showPop="showPop" @closeCartDialog="showPop1=false" :tab="tab"></cartDialog>
        
</header>

</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ActionButton from "./ActionButton.vue";
import svgCart from './svgButtons/svgCart.vue'
import svgWishlist from './svgButtons/svgWishlist.vue'
import svgGoToShop from './svgButtons/svgGoToShop.vue'
import svgLogout from './svgButtons/svgLogout.vue'
import svgMobileCart from './svgButtons/svgMobileCart.vue'
import svgOrders from './svgButtons/svgOrders.vue'
import cartDialog from '@/components/common/cartDialog.vue'
import settings from '../settings.json'
import axios from "axios";
export default {
    components: { cartDialog, ActionButton, svgCart, svgGoToShop, svgLogout, svgMobileCart, svgOrders, svgWishlist },
    name: "MainHeader",
    props: {
        msg: String,
    },
    data() {
        return {
            showSideNav: false,
            showDropDown: false,
            publicPath: process.env.BASE_URL,
            appSettings: settings,
            loading: true,
            // tab: 'shopping-cart',
            // showPop: false
        };
    },
    async created() {
        this.loading = false
        this.$store.dispatch('initApp')
    },
    computed: {
        ...mapState(["user", "cart","logInfo","wishList","userLogged","showPop", "tab"]),
        ...mapGetters(["getHash"]),
        getCartCount () {
            return this.cart.length
        },
        getWishList () {
            return this.wishList
        },
        getWishlistCount () {
            return this.wishList.length
        }
    },
    methods: {
        ...mapActions(["remove_user"]),
        setMessage (typ, message) {
            const payload = {typ: typ, message: message }
            this.$store.dispatch('setToast', payload)
        },
        showNav() {
            this.showSideNav = true;
        },
        hideNav() {
            this.showSideNav = false;
        },
        logout() {
            this.remove_user();
            this.setMessage('info','You are now logged out of Sahoora Collections')
            this.$router.push("/login");
        },
        showCartPopup (tab) {
            // this.showPop = true
            const payload = { show: true, tab: tab }
            console.log('[auyload]', payload)
            this.$store.dispatch('setCartPop', payload)
            // this.tab = tab
        }
    },
    
};
</script>
