import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import VueAwesomePaginate from "vue-awesome-paginate";
import VueCookie from "vue-cookies";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "vue-awesome-paginate/dist/style.css";
import VImageMagnifier from "v-image-magnifier";
import infiniteScroll from 'vue-infinite-scroll'

createApp(App).use(router).use(store).use(VueAwesomePaginate).use(VueCookie).use(VImageMagnifier).use(Toast).use(infiniteScroll).mount("#app");
