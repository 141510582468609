<template>
    <div id="divContainer">
    <section>
		<img class="logo" src="https://sahooracollection.com/media/landing/logo.png"/>
		<h1>Enrich <br/> Your <br/>Elegance</h1>
		<img src="https://sahooracollection.com/media/landing/barcode01.png" class="barcode"/>
		<a href="/shop" class="shop-now btn btn-1">SHOP NOW</a>
		<nav>
	      <a href="tel:+96555800068"><img src="https://sahooracollection.com/media/landing/phone.png" class="img-fluid"/></a>
	      <a href="https://www.instagram.com/sahoora_collection/"><img src="https://sahooracollection.com/media/landing/instagram.png" class="img-fluid"/></a>
	      <a href="https://www.sahooracollection.com/"><img src="https://sahooracollection.com/media/landing/website.png" class="img-fluid"/></a>
	  </nav>
</section>
    </div>
</template>
<script>
	</script>

<style scoped>
    h1{ font-size: calc(1em + 3vw); color: #E9E9E9; text-align: center; text-transform: uppercase; letter-spacing: 2px; font-weight: 600; animation: lights 5s 750ms linear infinite; }
    .logo{ animation: zoom-in-zoom-out 1s ease; max-width: 300px; margin-top: 40px; }
    #divContainer{ background: #231f20; font-family: "Raleway", sans-serif; margin: 0 }
    .row{ display: flex; align-items: center; justify-content: center; flex-wrap: wrap;}
    img{ max-width: 100%; width: auto; height: auto; }
    nav{ width: 100vw; background: #f0bb42; display: flex; flex-wrap: wrap; justify-content: space-around; align-items: center; padding-top: 8px;}
    nav a{ display: block; width: 40vmin; }
    .barcode{ min-width: 210px; }
    section {
		min-height: 100vh;
		display: flex; 
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 0 10px;
	}
	.btn {
		flex: 0 1 auto;
		margin: 2px;
		padding: 10px 20px;
        text-align: center;
        text-transform: uppercase;
        transition: 0.5s;
        background-size: 200% auto;
        color: #000;
        border-radius: 10px;
        text-decoration: none;
	}
	.btn:hover { background-position: right center; font-size: 1.2em; }
	.btn-1 { background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%); }
    @media (max-width: 767px){ nav a{ min-width: 30vmax; } }
    @media (max-width: 560px){ .logo{ max-width: 98%; } }
    @keyframes lights {
		0% {
		    color: hsl(230, 40%, 80%);
		    text-shadow:
		      0 0 1em hsla(320, 100%, 50%, 0.2),
		      0 0 0.125em hsla(320, 100%, 60%, 0.3),
		      -1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
		      1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
		}
		  
		30% { 
		    color: hsl(230, 80%, 90%);
		    text-shadow:
		      0 0 1em hsla(320, 100%, 50%, 0.5),
		      0 0 0.125em hsla(320, 100%, 60%, 0.5),
		      -0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
		      0.5em 0.125em 0.25em hsla(200, 100%, 60%, 0.4);
		}
		  
		40% { 
		    color: hsl(230, 100%, 95%);
		    text-shadow:
		      0 0 1em hsla(320, 100%, 50%, 0.5),
		      0 0 0.125em hsla(320, 100%, 90%, 0.5),
		      -0.25em -0.125em 0.125em hsla(40, 100%, 60%, 0.2),
		      0.25em 0.125em 0.125em hsla(200, 100%, 60%, 0.4);
		}
		  
		70% {
		    color: hsl(230, 80%, 90%);
		    text-shadow:
		      0 0 1em hsla(320, 100%, 50%, 0.5),
		      0 0 0.125em hsla(320, 100%, 60%, 0.5),
		      0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
		      -0.5em 0.125em 0.25em hsla(200, 100%, 60%, 0.4);
		}
		  
		100% {
		    color: hsl(230, 40%, 80%);
		    text-shadow:
		      0 0 1em hsla(320, 100%, 50%, 0.2),
		      0 0 0.125em hsla(320, 100%, 60%, 0.3),
		      1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
		      -1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
		}
		  
	}
    @keyframes zoom-in-zoom-out {
        0% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(1.5, 1.5);
        }
        100% {
            transform: scale(1, 1);
        }
    }
</style>
