<template>
	<footer class="site-footer footer-dark">
		<!-- Footer Top -->
		<div class="footer-top" style="padding-top:10px !important;padding-bottom:0px !important">
			<div class="container">
				
				<div class="row">
					<div class="col-md-6">
						<div class="widget widget_about me-2" style="margin-bottom:0px !important;">
							<div class="footer-logo logo-white">
								<router-link to="/shop"><img :src="appSettings.logo" alt=""></router-link>
							</div>
							<ul class="widget-address nav-inline" style="margin-bottom:10px !important;">
								<li>
									<p><a :href="'mailto:' + appSettings.contactEmail">{{ appSettings.contactEmail }}</a></p>
								</li>
								<li>
									<p>| <a :href="'https://api.whatsapp.com/send?phone=' + appSettings.whatsApp ">{{ appSettings.phone }}</a></p>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-md-6">
						<div class="widget widget_services text-lg-end">
							<ul class="nav-inline mb-3">
								<li><router-link to="/about">About Us</router-link></li>
								<li><router-link to="/contact">Contact Us</router-link></li>
								<li><router-link to="/privacy-policy">Privacy</router-link></li>
								<li><router-link to="/faqs">FAQ</router-link></li>
							</ul>
							<ul class="nav-inline">
								<li v-if="appSettings.facebook !== ''"><a :href="appSettings.facebook" target="_blank" class="site-button-link facebook hover"><i class="fa fa-2x fa-facebook-f"></i></a></li>
								<li><a v-if="appSettings.linkedin !== ''" :href="appSettings.linkedin" target="_blank" class="site-button-link linkedin hover"><i class="fa fa-2x fa-linkedin-in"></i></a></li>
								<li><a v-if="appSettings.pinterest !== ''" :href="appSettings.pinterest" target="_blank" class="site-button-link linkedin hover"><i class="fa fa-2x fa-pinterest"></i></a></li>
								<li><a v-if="appSettings.youtube !== ''" :href="appSettings.youtube" target="_blank" class="site-button-link linkedin hover"><i class="fa fa-2x fa-youtube"></i></a></li>
								<li><a v-if="appSettings.instagram !== ''" :href="appSettings.instagram" target="_blank" class="site-button-link instagram hover"><i class="fa fa-2x fa-instagram"></i></a></li>
								<li v-if="appSettings.twitter !== ''"><a :href="appSettings.twitter" target="_blank" class="site-button-link twitter hover"><i class="fa fa-2x fa-twitter"></i></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Footer Top End -->
		
		<!-- Footer Bottom -->
		<div class="footer-bottom" style="padding-bottom:30px !important">
			<div class="container">
				<div class="row fb-inner" style="padding:0px !important">
					<div class="col-lg-6 col-md-12 text-start"> 
						<p class="copyright-text" v-html="appSettings.copyright"> </p>
					</div>
					<div class="col-lg-6 col-md-12 text-end"> 
						<div class="d-flex align-items-center justify-content-center justify-content-md-center justify-content-lg-end">
							<span class="me-3">We Accept: </span>
							<img style="height:30px;margin-right:5px;padding-right:5px;" v-for="(items, ndx) in appSettings.paymentmethods" :key="ndx" :src="items.image" :alt="items.name"/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Footer Bottom End -->
		
	</footer>
    
</template>

<script>
import settings from '../settings.json'
import socialMedia from './svgButtons/socialMedia.vue'
export default {
    name: "MainFooter",
    components: { socialMedia },
    data(){
        return {
            date: new Date().getFullYear(),
            appSettings: settings
        }
    }
};
</script>

<style scoped>
/* footer {
    border-top: 1px solid var(--brown);
} */
.footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-block: 2rem;
    gap: 1.2rem;
    background:#000;
}

.logo {
    margin-block: 2rem;
    display: inline-block;
}

.footer-col {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
}

.footer-col h4 {
    padding-bottom: 1.3rem;
}
.footer-col strong { color:#fff}
.footer-col a {
    font-size: 1.6rem;
}

.footer-col a:hover {
    color: var(--dark-green);
}

.footer-col span {
    display: block;
    font-size: 1.6rem;
}
strong {
    color: var(--text);
}

.social-links a {
    padding-right: 1.4rem;
}

.copyright {
    font-size: 1.7rem;
    width: 100%;
    text-align: center;
}

.copyright span {
    font-weight: 700;
    color: var(--dark-green);
}

.install p {
    padding-block: 0;
}

.apps {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.download-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    border: 2px solid var(--text);
    border-radius: 6px;
    padding: 8px;
    text-align: center;
}

.download-btn svg {
    font-size: 2rem;
}

.apps a:hover {
    background-color: var(--dark-green);
    color: white;
    border-color: var(--dark-green);
}

.apps a:hover p {
    color: white;
}

.payment-methods {
    display: flex;
    align-items: center;
    gap: 10px;
}
.payment-methods img {
    height: 40px;
    width: 100%;
}

.payment-methods svg:hover {
    cursor: pointer;
    color: var(--dark-green);
}

@media (max-width: 399px) {
    .download-btn p {
        font-size: 1.4rem;
    }
    .download-btn span {
        font-size: 1.4rem;
    }
}

@media (max-width: 345px) {
    .apps {
        display: flex;
        flex-direction: column;
    }
}
#footerBar {background:#000; color:#fff;}
.footer-text {color:#fff;}
.bottomSocialLinks {
    place-content: center;
    text-align: center;
    /* margin-bottom: 2.5rem; */
    display: flex;
    /* margin-top: 2.5em; */
    width:100%;
}
</style>
