import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { VueCookie } from "vue-cookies";
const store = createStore({
    state: {
        user: null,
        userLogged: false,
        cart: [],
        products: [],
        logInfo: {},
        orderInfo: {},
        wishList: [],
        toastMessage: {},
        showPop: false,
        tab: ''
    },
    mutations: {
        loginUser(state, payload) {
            state.user = payload;
        },
        logoutUser(state) {
            state.user = null;
            state.userLogged = false;
            const cookieParams = JSON.stringify(state.user)
            $cookies.set('sahoorauser', cookieParams, {
                      path: '/',
                      maxAge: 60 * 60 * 24 * 7
                  });
        },
        addToCart(state, payload) {
            if (state.cart.length != 0) {
                let item = state.cart.find((item) => item.slug === payload.slug);
                if (item) {
                    if (item.size === payload.size && item.color === payload.color) {
                        if (item.quantity < 10) {
                            item.quantity += payload.quantity;
                        } else if (item.quantity >= 10) {
                            item.quantity = 10;
                        }
                    } else {
                        state.cart = [...state.cart, payload];
                    }
                } else {
                    state.cart = [...state.cart, payload];
                }
            } else {
                state.cart = [...state.cart, payload];
            }
            const p = {typ: 'success', message: payload.name + ' added to your cart' }
            state.toastMessage = p
            const cookieParams = JSON.stringify(state.cart)
            $cookies.set('cart', cookieParams, {
                      path: '/',
                      maxAge: 60 * 60 * 24 * 7
                  });
        },
        deleteItem(state, payload) {
            let index = state.cart.indexOf(payload);
            state.cart.splice(index, 1);
            const cookieParams = JSON.stringify(state.cart)
            $cookies.set('cart', cookieParams, {
                      path: '/',
                      maxAge: 60 * 60 * 24 * 7
                  });
        },
        updateQuantity(state, payload) {
            if (payload.quantity > 0) {
                let item = state.cart.find(
                    (item) =>
                        item.id === payload.id && item.size === payload.size && item.color === payload.color
                );
                item.quantity = payload.quantity;
            }
            const cookieParams = JSON.stringify(state.cart)
            $cookies.set('cart', cookieParams, {
                      path: '/',
                      maxAge: 60 * 60 * 24 * 7
                  });
        },
        emptyCart(state) {
            state.cart = [];
            const cookieParams = JSON.stringify(state.cart)
            $cookies.set('cart', cookieParams, {
                      path: '/',
                      maxAge: 60 * 60 * 24 * 7
                  });
        },
        setProducts(state, payload) {
            state.products = payload;
        },
        autoLogin(state, payload) {
            state.logInfo = payload
        },
        setOrder(state, payload) {
            state.orderInfo = payload
        },
        addWishList(state, payload) {
            let lst = state.wishList.filter(i => i.id === payload.id)
            if(lst.length === 0) {
                state.wishList.push(payload)
            }
            const p = { typ: 'info', message: payload.name + ' has been added to your wishlist' }
            state.toastMessage = p
            const cookieParams = JSON.stringify(state.wishList)
            $cookies.set('wishlist', cookieParams, {
                      path: '/',
                      maxAge: 60 * 60 * 24 * 7
                  });
        },
        removeWishList(state, payload) {
            let lst = state.wishList.filter(i => i.id !== payload.id)
            state.wishList = lst
            const p = { typ: 'info', message: payload.name + ' has been removed from your wishlist' }
            state.toastMessage = p
            const cookieParams = JSON.stringify(state.wishList)
            $cookies.set('wishlist', cookieParams, {
                      path: '/',
                      maxAge: 60 * 60 * 24 * 7
                  });
        },
        initApp (state, payload) {
            if (payload.wishList !== null) {
                state.wishList = payload.wishList
            } else {
                state.wishList = []
            }
            state.user = payload.user
            if (state.user === null || state.user === 'null') {
            } else {
                if ('id' in state.user) {
                    state.userLogged = true
                }
            }
        },
        setToast(state, payload) {
            state.toastMessage = payload
        },
        setUser (state, payload) {
            state.user = payload
            state.userLogged = true
            const cookieParams = JSON.stringify(payload)
            $cookies.set('sahoorauser', cookieParams, {
                      path: '/',
                      maxAge: 60 * 60 * 24 * 7
                  });
        },
        setCartPop (state, payload) {
            state.showPop = payload.show
            state.tab = payload.tab
        }
    },
    actions: {
        set_user(context, payload) {
            context.commit("loginUser", payload);
        },
        remove_user(context) {
            context.commit("logoutUser");
        },
        add_to_cart(context, payload) {
            context.commit("addToCart", payload);
        },
        delete_item(context, payload) {
            context.commit("deleteItem", payload);
        },
        update_quantity(context, payload) {
            context.commit("updateQuantity", payload);
        },
        clear_cart(context) {
            context.commit("emptyCart");
        },
        set_products(context, payload) {
            context.commit("setProducts", payload);
        },
        auto_login(context, payload) {
            context.commit("autoLogin", payload);
        },
        set_orders(context, payload) {
            context.commit("setOrder", payload);
        },
        addWishList(state, payload)  {
            
            state.commit('addWishList', payload)
        },
        removeWishList(state, payload) {
            state.commit('removeWishList', payload)
        },
        initApp (state, payload) {
            const p = {}
            p.wishList = $cookies.get('wishlist')
            p.user = $cookies.get('sahoorauser')
            state.commit('initApp', p)
        },
        setToast (state, payload) {
            state.commit('setToast', payload)
        },
        setCartPop (state, payload) {
            console.log('action....', payload)
            state.commit('setCartPop', payload)
        }
        
    },
    getters: {
        subtotal: (state) => {
            let subtotal = 0;
            state.cart.forEach((item) => {
                subtotal += item.productPrice * item.quantity;
            });
            return subtotal;
        },
        getProductDescription: (state, item) => {
           return item.id
        },
        getHash: (state) => {
            var md5 = require("blueimp-md5/js/md5");
            var hash = md5(state.user.email);
            return `https://www.gravatar.com/avatar/` + hash + `?d=mp`;
        },
    },
    plugins: [createPersistedState()],
});

//export store
export default store;
